// Variables
$checkbox-size: 25px;
$checkbox-border: #999999;
$checkbox-selected: #02BB72; 
input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: inline-block; // set to `inline-block` as `inline elements ignore `height` and `width`
  height: 22px;
  width: 22px;
  background: rgb(255, 255, 255);
  border: 2px #ddd solid;
  margin-right: 4px;
}

.checkbox--active {
  border-color: rgb(0, 97, 0);
  background: rgb(39, 180, 21);
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 150px;
  background-color: white;
}

@media (min-width: 576px) {
  .modal-dialog {
  max-width: max-content;
  margin: 1.15rem auto;
  }
  }