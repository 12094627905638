.custom-checkbox-label {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  font-size: large;
  text-align: center;
  cursor: pointer;
  margin-right: 0px;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}
.custom-checkbox-label:hover {
  border-color: #999;
}
.custom-checkbox-label.selected {
  background-color: #007bff;
  color: #fff;
}
