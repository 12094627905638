.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: rgb(7, 7, 7);
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}
.rotateimg180 {
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  border: 0.66pt solid #b5b5b5;
}

.imagecardstyle
{
  flex-wrap: wrap;
  display: flex;
  text-indent: 1em;
  margin-block-end: 0.5em;
  margin-block-start: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.50em;
  letter-spacing: 0.1pt;

}